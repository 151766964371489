import { Socials } from '@/components/Socials';
import styles from './Footer.module.scss';
import { FooterLink } from '@/components/FooterLinks';

import { WorkPeriod } from '@/components/WorkPeriod';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { TABLET_RESOLUTION } from '@/constants/constants';
import { FooterLinkType, ModeType, SocialType } from '@/types';

type FooterProps = {
  menu: FooterLinkType[];
  timeTable: ModeType[];
  socials: SocialType[];
  disclaimer: string;
  copyright: string;
  address: string;
};

export function Footer({
  menu,
  timeTable,
  disclaimer,
  copyright,
  address,
  socials,
}: FooterProps) {
  const width = useWindowWidth();

  const howToReachLink = menu[0];

  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <img
          src={'/svg/logo.svg'}
          width={64}
          height={83}
          alt="logo"
          className={styles.logo}
        />
        <div className={styles.main}>
          <div className={styles.contacts}>
            <div className={styles.address}>
              <p className={styles.addressTitle}>Наш адрес</p>
              <address className={styles.addressText}>{address}</address>
            </div>
            <Socials socials={socials} />
          </div>
          {width && width < TABLET_RESOLUTION ? (
            <>
              <div className={styles.footerLinksItem}>
                <FooterLink
                  text={howToReachLink.name}
                  href={howToReachLink.link}
                />
              </div>
              <div className={styles.workPeriod}>
                <WorkPeriod items={timeTable} />
              </div>
              <div className={styles.links}>
                {menu.slice(1).map((item) => (
                  <div className={styles.footerLinksItem} key={item.name}>
                    <FooterLink text={item.name} href={item.link} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className={styles.workPeriod}>
                <WorkPeriod items={timeTable} />
              </div>
              <div className={styles.links}>
                {menu.map((item) => (
                  <div className={styles.footerLinksItem} key={item.name}>
                    <FooterLink text={item.name} href={item.link} />
                  </div>
                ))}
              </div>
            </>
          )}
          <div className={styles.disclaimer}>
            <p className={styles.disclaimerTitle}>{copyright}</p>
            <p className={styles.disclaimerText}>{disclaimer}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
